// Images file for 'qualifiedsolarsurvey'.
// The export of 'Favicon' and 'Logo' must match those names exactly.
// Use 'images.js' in the 'harpquiz' directory as a guide.

import Arrow from './assets/nextArrow.png';
import CircleDown from './assets/circleDown.png';
import Checked from './assets/checked.png';
import Dropdown from '../../../../src/images/dropdown_carat.png';
import Favicon from './assets/favicon.ico';
import MobileLogo from './assets/favicon.png';
import LittleShade from './assets/LittleShade.png';
import LotShade from './assets/LotShade.png';
import No from './assets/noX.png';
import NoShade from './assets/NoShade.png';
import SingleFamily from './assets/singleFamily.png';
import Townhouse from './assets/townhouse.png';
import Uncertain from './assets/Uncertain.png';
import Unchecked from './assets/unchecked.png';
import Yes from './assets/yesCheck.png';

export default {
  Arrow,
  CircleDown,
  Checked,
  Dropdown,
  Favicon,
  LittleShade,
  LotShade,
  MobileLogo,
  No,
  NoShade,
  SingleFamily,
  Townhouse,
  Uncertain,
  Unchecked,
  Yes,
};
